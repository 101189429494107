/* Version 0.0.1 */

@font-face {
    font-family: "Azonix";
    src: url('./fonts/azonix.otf')
}

@font-face {
    font-family: "Consolas";
    src: url('./fonts/consola.ttf')
}

@font-face {
    font-family: "Spartan";
    src: url('./fonts/spartan.ttf')
}

@font-face {
    font-family: "Tahoma";
    src: url('./fonts/consola.ttf')
}

@keyframes fadeIn {
    0% { opacity: 0.0; background-color: #000016; }
  100% { opacity: 1.0; }
}

:root{
    --background-Color: rbga(0, 0, 16, 1.0);
}

img {
    opacity: 1.00;
}

img:hover {
    opacity: 1.00;
    -webkit-transition: opacity 1s;
    transition: opacity 1s;
}

.bodyClass {
    padding: 0px;
    margin: 0px;
    width: 100%;
    height:100%;
    overflow: hidden;
}

.divHeader{
    background: darkblue;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 105px;
    opacity: 0.25
}

.divMain {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.extraPadding {
    margin-left: 30px;
}

.footerPadding {
    height: 175px;
}

.headerHeight {
    height: 575px;
}

.logo {
    position: absolute;
    top: 20px;
    left: 50px;
    width: 64px;
}

.logoWidth {
    width: 64px;
}

.portal {
    background-color: #000016;
    background-image: url('https://alexsouza.blob.core.windows.net/media/images/dev/portal1920.png');
    animation: fadeIn 1250ms;
}

.projectPadding {
    padding-left: 0px;
}

.projectWidth {
    width: 80px;
}

.portalVideo {
    animation-delay: 1250ms;
    animation: fadeIn 250ms;
}

.portalVideoWidth {
    width: 100%; 
}

.titleText {
    font-family: 'Azonix';
    font-size: 54px;
    font-weight: 700;
    color: whitesmoke;
    text-align: center;
}

.subTitleText {
    font-family: 'Spartan';
    font-size: 20px;
    color: whitesmoke;
    text-align: center;
}

/* Scaling Max Width 1280 */
@media only screen and (min-width: 1280px) {

    .footerPadding {
        height: 120px;
    }

    .headerHeight {
      height: 380px;
    }

    .logoWidth {
        width: 64px;
    }

    .projectPadding {
        margin-left: 0px; 
    }

    .projectWidth {
        width: 128px;
    }

    .portal {
        background-image: url('https://alexsouza.blob.core.windows.net/media/images/dev/portal1280.png');
    }

    .subTitleText {
        font-size: 18px;
    }

    .titlePadding {
        margin-left: 25px; 
    }

    .titleText {
        font-size: 30px;
    }
}

/* Scaling Max Width 1366 */
@media only screen and (min-width: 1366px) {

    .footerPadding {
        height: 210px;
    }

    .headerHeight {
      height: 400px;
    }

    .logoWidth {
        width: 64px;
    }

    .projectPadding {
        margin-left: 0px; 
    }

    .projectWidth {
        width: 128px;
    }

    .portal {
        background-image: url('https://alexsouza.blob.core.windows.net/media/images/dev/portal1366.png');
    }

    .subTitleText {
        font-size: 20px;
    }

    .titlePadding {
        margin-left: 30px;
    }

    .titleText {
        font-size: 30px;
        font-weight: 700;
    }
}

/* Scaling Max Width 1920 */
@media only screen and (min-width: 1920px) {

    .footerPadding {
        height: 175px;
    }

    .headerHeight {
      height: 575px;
    }

    .logoWidth {
        width: 64px;
    }

    .projectPadding {
        margin-left: 15px; 
    }

    .projectWidth {
        width: 128px;
    }

    .portal {
        background-image: url('https://alexsouza.blob.core.windows.net/media/images/dev/portal1920.png');
    }

    .subTitleText {
        font-size: 20px;
    }

    .titlePadding {
        margin-left: 30px;
    }

    .titleText {
        font-size: 54px;
        font-weight: 700;
    }
}